import React, {useEffect} from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import LoginMenuComponent from "./LoginMenuComponent/LoginMenuComponent";
import UserAvatarMenuComponent from "./UserAvatarMenuComponent/UserAvatarMenuComponent";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getUser} from "../../asyncActions/user/get_user";
import {NavLink} from "react-router-dom";


const DesktopComponent: React.FC = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const dispatch = useAppDispatch()
    const user = useAppSelector(state => state.user)

    useEffect(() => {
        dispatch(getUser())
    },[]);

    return(
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            // component="div"
                            noWrap
                            component={NavLink}
                            to="/"
                            sx={{ flexGrow: 1 }}
                        >
                            Man's Networking
                        </Typography>
                        {/*{!(userLicense.name===undefined || userLicense.name==="") && <p>License: {userLicense.name}</p>}*/}
                        {!(user.email===undefined || user.email==="") && <div style={{marginLeft: "16px"}}><p>Email: {user.email}</p></div>}

                        {(user.email===undefined || user.email==="") && <Button component={NavLink} to="/register" variant="text" color="inherit" >Register</Button>}
                        {(user.email===undefined || user.email==="") && <Button component={NavLink} to="/login" variant="text" color="inherit" >Login</Button>}
                        {!(user.email===undefined || user.email==="") && <UserAvatarMenuComponent />}
                        <LoginMenuComponent anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
                    </Toolbar>
                </AppBar>
            </Box>
        </>
    )
}

export default DesktopComponent;