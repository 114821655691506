import React, {useEffect} from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../../hooks/redux";


const IndexPage: React.FC = () => {
    const navigate = useNavigate();
    const user = useAppSelector(state => state.user)


    return(
        <>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                }}
            >
            </Box>
        </>
    )
}

export default IndexPage;