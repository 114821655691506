import React, {useState} from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import {Paper, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import {useAppDispatch} from "../../hooks/redux";
import {useNavigate} from "react-router-dom";
import {registerUser} from "../../asyncActions/user/registerUser";
import {useCookies} from "react-cookie";
import Checkbox from '@mui/material/Checkbox';
const check_undefined = (data: string | undefined) : string => {
    if (data === undefined){
        return ''
    } else {
        return data
    }
}

const RegisterPage: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const [emailCookie, setEmailCookie] = useCookies(['register_email']);
    const [loginCookie, setLoginCookie] = useCookies(['register_login']);
    const [loginTelegramCookie, setLoginTelegramCookie] = useCookies(['register_telegram']);

    const [email, setEmail] = useState<string>(check_undefined(emailCookie.register_email));
    const [login, setLogin] = useState<string>(check_undefined(loginCookie.register_login));
    const [telegramUsername, setTelegramUsername] = useState<string>(check_undefined(loginTelegramCookie.register_telegram));
    const [firstName, setFirstName] = useState<string>("");
    const [secondName, setSecondName] = useState<string>("");
    const [userInfo, setUserInfo] = useState<string>("");
    const [checked, setChecked] = React.useState(true);


    const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== undefined){
            setEmail(e.target.value)
            setEmailCookie('register_email', e.target.value)
        }
    }
    const handleLogin = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== undefined){
            setLogin(e.target.value)
            setLoginCookie('register_login', e.target.value)
        }
    }

    const handleTelegramUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== undefined){
            setTelegramUsername(e.target.value)
            setLoginTelegramCookie('register_telegram', e.target.value)
        }
    }

    const handleFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== undefined){setFirstName(e.target.value)}
    }

    const handleSecondName = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== undefined){setSecondName(e.target.value)}
    }

    const handleUserInfo = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== undefined){setUserInfo(e.target.value)}
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const handleRegisterOnClick = () => {
        dispatch(registerUser(email, login, telegramUsername, firstName, secondName, userInfo))
        setTimeout(() => {
            navigate("/");
        }, 2000);
    }

    function isValidEmail(email: string) {
        return /\S+@\S+\.\S+/.test(email);
    }
    return(
        <>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '85vh',
                    alignItems: "center",
                    justifyContent: 'center',
                }}
            >
                <Box
                    maxWidth="80vw"
                    minWidth="60vw"
                >
                    <Paper />
                    <div style={{margin: "16px"}}>
                        <div style={{marginTop: "16px"}}>
                            <TextField
                                fullWidth
                                required
                                error={!isValidEmail(email)}
                                helperText={!isValidEmail(email) && "Email format problem"}
                                value={email}
                                label="Email"
                                onChange={handleEmail}
                            />
                        </div>

                        <div style={{marginTop: "16px"}}>
                            <TextField
                                fullWidth
                                required
                                error={login.length===0}
                                helperText={login.length===0 && "Enter login"}
                                value={login}
                                label="Username"
                                onChange={handleLogin}
                            />
                        </div>

                        <div style={{marginTop: "16px"}}>
                            <TextField
                                fullWidth
                                required
                                helperText={handleTelegramUsername.length === 0 && "Enter telegram username"}
                                value={telegramUsername}
                                label="Telegram username"
                                onChange={handleTelegramUsername}
                            />
                        </div>
                        <div style={{marginTop: "16px"}}>
                            <TextField
                                fullWidth
                                value={firstName}
                                label="First name"
                                onChange={handleFirstName}
                            />
                        </div>
                        <div style={{marginTop: "16px"}}>
                            <TextField
                                fullWidth
                                value={secondName}
                                label="Second name"
                                onChange={handleSecondName}
                            />
                        </div>
                        <div style={{marginTop: "16px"}}>
                            <TextField
                                fullWidth
                                value={userInfo}
                                label="User info"
                                onChange={handleUserInfo}
                                multiline
                                rows={3}
                            />
                        </div>
                        <div>
                            <Checkbox
                                checked={checked}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                        <div style={{marginTop: "16px"}}>
                            <Button
                                variant="contained"
                                onClick={handleRegisterOnClick}
                                disabled={!checked || !isValidEmail(email) || login.length===0 || telegramUsername.length === 0}
                            >Register</Button>
                        </div>
                    </div>
                </Box>
            </Box>
        </>
    )
}

export default RegisterPage;