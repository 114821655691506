import {ILoginUser} from "../types/redux_types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState: ILoginUser = {
    id: "",
    email: "",
    login: "",
    telegram_username: "",
    first_name: "",
    second_name: "",
    user_info: ""
}

export const LoginSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        redux_login (state, action: PayloadAction<ILoginUser>){
            state.id = action.payload.id;
            state.email = action.payload.email;
            state.login = action.payload.login;
            state.telegram_username = action.payload.telegram_username;
            state.first_name = action.payload.first_name;
            state.second_name = action.payload.second_name;
            state.user_info = action.payload.user_info;
        },
        redux_logout(state){
            state.id = "";
            state.email = "";
            state.login = "";
            state.telegram_username = "";
            state.first_name = "";
            state.second_name = "";
            state.user_info = "";
        }
    }
})

export default LoginSlice.reducer;